.EnterOtp {
    height: 60vh;
    display: grid;
    justify-items: center;
    align-items: center;
    grid-column: 1/3;
}
.EnterOtp form {
    display: grid;
    justify-items: center;
    height: fit-content;
}
.EnterOtp form input {
    width: 500px;
    height: 40px;
    border-radius: 3px;
    padding: 5px;
    border: 1px solid #454545;
}
.EnterOtp button {
    width: 200px;
    margin-top: 10px;
}

.otp_sent{
    margin-bottom: 5%;
    color: #f4714f;
}

