.ListQuotes {
    padding: 1rem 0.1rem;
    padding-right: 1rem;
    overflow-y: auto;
    height: 58vh;
}
.ListQuotes .list-quotes-grid {
    display: grid;
    grid-template-columns: 3fr 1fr;
}
.ListQuotes .list-quotes-grid .edit {
    color: #3b3a3a;
    font-size: 1.5rem;
    padding: 0 5px;
}
.ListQuotes .list-quotes-grid .edit:hover {
    cursor: pointer;
    color: #1a1a1a;
    transition: all 0.3s ease-in-out;
}
.ListQuotes .list-quotes-grid .remove {
    color: #353535;
    font-size: 1.5rem;
    padding: 0 5px;
}
.ListQuotes .list-quotes-grid .remove:hover {
    cursor: pointer;
    color: #1a1a1a;
    transition: all 0.3s ease-in-out;
}
.ListQuotes .quotes-icon {
    justify-self: flex-end;
}
.ListQuotes hr {
    background: #ffffff;
    height: 0.4px;
    margin: 15px 0;
}
.ListQuotes .quote-title {
    margin: 0;
}
.ListQuotes .quote-date {
    margin-top: 3px;
    color: rgb(173, 173, 173);
    font-size: 12px;
}

.quote-info-container {
    display: flex;
    flex-direction: column;
}