.reset-form {
  justify-self: center;
  position: relative;
  top: 25%;
  width: 65%;
}
.ResetPassword {
  height: 100vh;
  display: grid;
  justify-items: center;
  grid-column: 1/4;
}

.ResetPassword h3 {
  font-size: 18px;
  margin-top: 50px;
  font-weight:600;
}
._passLabel_{
    width: 50%;
}
._password_ {
  width: 660px;
  display: flex;
  justify-content: space-between;
}
._reset_ {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  margin-bottom: 3%;
  margin-left: 6%;
}
._passInput_{
    width: 320px;
    height: 40px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #ed2939; 
    align-items: center;  
}
._passInput2_{
    width: 320px;
  height: 40px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ed2939;  
  align-items: center;
}
/* .ResetPassword input {
  width: 300px;
  height: 40px;
  margin-bottom: 10px;
  border-radius: 25px;
  border: 1px solid #ed2939;
} */

.ResetPassword form {
  display: grid;
  /* justify-items: center; */
  height: fit-content;
}

.ResetPassword form h5 {
  color: #454545;
  font-size: 15px;
  margin-right: 115px;
}

.ResetPassword form label {
  padding-right: 20px;
  font-size: 15px;
}

.ResetPassword i {
  font-size: 18px;
  position: relative;
  right: 36px;
  top: 4px;
}

.ResetPassword button {
  width: 300px;
  position: relative;
  right: 60px;
  margin-top: 40px;
}

.password_reset_successfull{
  color:#28a745;
}
.password_mismatch_warning{
  color:#dc3545;
}
