.Craete-Clips {
    margin-bottom: 30px;
}
.Craete-Clips span {
    font-size: 2.2vh;
}
.Craete-Clips form {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
}
.Craete-Clips form input {
    border: none;
    box-shadow: 0px 1px 2px 0px grey;
    width: 90%;
    border-radius: 5px;
    font-size: 14px;
    padding: 0px 20px;
    height: 45px;
}
.Craete-Clips form button {
    width: 85%;
    background: #353535;
    border: none;
    border-radius: 5px;
}

.Craete-Clips form button:hover {
    cursor: pointer;
    background-color: #1a1a1a !important;
    transition: all 0.3s ease-in-out;
}