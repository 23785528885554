.ProfileDetails {
    margin: 3rem;
    
}
.ProfileDetails h3 {
    font-size: 1.8rem;
    letter-spacing: 4px;
}
.ProfileDetails form .form-width-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
.ProfileDetails input, textarea {
    background: #fdfafa;
    width: 90%;
    border: 1px solid rgb(248, 171, 171);
    border-radius: 5px;
}
.ProfileDetails form input {
    height: 30px;
}

.ProfileDetails form textarea {
    min-height: 100px;
}

.ProfileDetails .center-button {
    display: grid;
    justify-items: center;
}

.save_btn {
    border: none;
    color: #fefefa;
    background: linear-gradient(to bottom, #ff0606, #fc7c04);
    width: 200px;
    position: relative;
    top: 50px;
    right: 28px;
}

.save_btn {
    border: none;
    color: #fefefa;
    background: linear-gradient(to bottom, #ff0606, #fc7c04);
    width: 200px;
    position: relative;
    top: 50px;
    right: 28px;
}



.imgdiv{
    position: relative;
}

.imgUpload{
    width: 10vw;
    height: 20vh;
    border-radius: 15px;
    display: flex;
    position: absolute;
    
}

.imgUpload_btn{
    border: none;
    color: #fefefa;
    background: rgb(192, 173, 173);
    width: 200px;
    position: relative;
    top: 20px;
    margin-left: 20px;
}

.writeIcon{
    height: 20vh;
    width: 10vw;
    border-radius: 15px;
    display: flex;
    background-color: #fdfafa;
margin-top: 30px;
    cursor: pointer;
    text-align: center;
    justify-content: center;
    align-items: center;
    position: relative;
    border: 1px solid rgb(248, 171, 171);
}

.upload_info{
    /* margin-top: 30px; */
    color: rgb(134, 133, 133);
    margin-left: 10px;
    font-weight: bold;
}

.uploadInfo_container{
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.imglabel::after{
content: '' !important;
}