.cr-container {
    padding: 20px;
}

.thead-cr {
    background-color: #454545 !important;
    color: white !important;
}

.cr-yes {
    font-weight: 600;
    background-color: #353535 !important;
    color: white !important;
    border-bottom: 1px solid white !important;
}

.cr-yes:hover {
    cursor: pointer;
    background-color: #1b1b1b !important;
    transition: all 0.3s ease-in-out;
}

.cr-no {
    font-size: 10px;
    font-weight: 600;
    background-color: #c5c5c5 !important;
    color: rgb(0, 0, 0) !important;  
    border-bottom: 1px solid #353535 !important;
}

.cr-no:hover {
    cursor: pointer;
    background-color: #b3b3b3 !important;
    transition: all 0.3s ease-in-out;
}

.tr-cr-head {
    font-size: 13px;
    background-color: #353535 !important;
    color: white !important;
}

.tr-cr-head > th {
    border-right: 1px solid white !important;
}

.tr-cr {
    border-top: 1px solid #353535 !important;
    border-bottom: none !important;
}