.ReportedProfile {
    margin: 3rem;
}
.ReportedProfile h3 {
    font-size: 1.8rem;
}
.ReportedProfile .btn {
    border: none;
    text-transform: uppercase;
    width: 100px;
    height: 40px;
}
.ReportedProfile .btn-block {
    background: #454545;
    color: red;
    width: 50%;
}
.ReportedProfile .btn-ignore {
    background: #b6b2b2;
    color: Black;
    width: 50%;
}
#pad-cut {
    padding: 0;
}