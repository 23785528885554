.bloglist-container {
    height: 100%;
    width: 100%;
    padding: 20px;
}

.blog-list-nav {
    height: 67px;
    display: flex;
    align-items: center;
    justify-content: end;
}

.blog-heading-nav {
    display: flex;
    align-items: end;
    justify-content: start;
    font-weight: 600;
    font-size: 1.25rem;
    padding-bottom: 12px;
}

.blog-table {
    border: none !important;
}

thead {
    background-image: linear-gradient(#dd715e, #ff6937);
    color: white;
}

tr {
    border-bottom: 2px solid #fda2aa !important;
}

td, th {
    text-align: center !important;
    vertical-align: middle;
}

.fa-custom-view {
    font-size: 30px;
    color: red;
}

.fa-custom-view:hover {
    cursor: pointer;
    color: rgb(194, 0, 0);
    transition: all 0.3s ease-in-out;
}

.info-row {
    min-height: 45px;
    align-items: center;
    margin: 10px 0px;
}