.loader-box-container {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.loader-box {
    position: relative;
    background-color: white;
    height: 8rem;
    width: 8rem;
    outline: 0;
    overflow: hidden;
}

.loader-box::before {
    content: url(/src/Images/logo-small.png);
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader-box::after {
    content: '';
    position: absolute;
    bottom: -50%;
    left: -50%;
    height: 250%;
    width: 250%;
    background: white;
    border-radius: 45%;
    animation: spin 3s ease-in-out infinite;
}

@keyframes spin {
    0% {
        transform: translateY(0) rotate(0deg);
    }
    100% {
        transform: translateY(-100%) rotate(500deg);
    }
}