.payout-container {
    padding: 20px;
}

.paynow {
    background-color: #353535 !important;
    color: white !important;
    font-size: 14px !important;
    font-weight: 600;
}

.paynow:hover {
    cursor: pointer;
    background-color: #1a1a1a !important;
    transition: all 0.3s ease-in-out;
}

.ot-amount {
    font-size: 26px;
    font-weight: bold;
    color: rgb(223 0 0);
    margin-left: 15px;
}

.paid {
    background-color: rgb(83, 167, 0) !important;
    color: white !important;
    font-weight: bolder !important;
}

.dec-button {
    width: 100px;
    font-size: 13px;
    height: 30px;
    border: none;
}

.dec-button.yes-dec {
    background-color: grey;
    color: white;
    font-weight: bolder;
}

.dec-button.yes-dec:hover {
    cursor: pointer;
    background-color: rgb(100, 100, 100);
    color: white;
    transition: all 0.3s ease-in-out;
}

.dec-button.cancel-dec {
    background-color: #e9e9e9;
    color: darkgrey;
    font-weight: bolder;
}

.dec-button.cancel-dec:hover {
    cursor: pointer;
    background-color: #cccccc;
    color: darkgrey;
    transition: all 0.3s ease-in-out;
}