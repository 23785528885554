.BlogEdit {
    margin: 3rem;
}
.BlogEdit .form-quote-width {
    align-items: center;
    justify-items: center;
    margin-left: 380px;
}
.BlogEdit .form-quote-width input{
    width: 360px;
    height: 30px;
}