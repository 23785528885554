.App {

}

.Login {
    grid-column: 1/3;
    grid-row: 1/3;
}

.Navbar {
    width: 250px;
    background-color: #454545;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
}

/* .Header {
    width: calc(100% - 250px);
    margin-left: 250px;
}

.Dasboard {
    width: calc(100% - 250px);
    margin-left: 250px;
}

.CreateSuppliers {
    width: calc(100% - 250px);
    margin-left: 250px;
} */

.main-section {
    width: calc(100% - 250px);
    left: 250px;
    position: absolute;
    top: 0;
}

body {
    overflow: hidden;
}

.navlinks-container {
    
}