.Clips-List{
    padding: 1rem 1rem 1rem 0.5rem;
    overflow-y: auto;
    height: 70vh;
}
.clip-list-grid {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    align-items: center;
}
.Clips-List img {
    width: 200px;
}
.Clips-List h4 {
    margin: 0;
}
.Clips-List i {
    color: #353535;
    font-size: 3rem;
}
.Clips-List i:hover {
    cursor: pointer;
    color: #1a1a1a;
    transition: all 0.3s ease-in-out;
}
.Clips-List hr {
    background: #ffffff;
    height: 0.4px;
    margin: 5px 0;
    grid-row: 2;
    grid-column: 1/4;
}

.Clips-List a {
    text-decoration: none;
    color: #ed2939;
}

.Clips-List a:hover {
    color: #ed2939;
}