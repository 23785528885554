.Navbar {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 150px))
}
.side-navbar {
    width: 100%;
    background-color: #454545;
    grid-column: 1;
    display: grid;
    grid-template-rows: repeat(8, minmax(30px));
    grid-gap: 10px;
    min-height: 800px;
}
._logoImg_{
    height: 45px;   
}
.white-logo {
    grid-column: 2;
    align-self: start;
    position: relative; 
    left: 15px;
    top: 10px;
}

.side-navbar div {
    position: relative;
    left: 30%;
    top: -50px;
    width: 60%;
}

.side-navbar img {
    width: 50%;
    justify-self: center;
    padding-top: 40px;
}

.side-navbar h2 {
    text-align: center;
    color: #ed2939;
    letter-spacing: 4px;
    position: relative;
    top: -38px;
}

.nav-link {
    color: #fefefe;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    height: 50px;
}

.icon-log {
    position: absolute;
    top: 18px;
    right: 25px;
}

.i-logout {
    color:#ed2939;
    font-size: 36px;
}

hr {
    color: red;
    width: 100%;
    height: 10px;
}

.active {
    color: #ed2939 !important;
}


.side-navbar-admin {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0px;
}

.navbar-logo {
    width: 42%;
}

.userinfo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
}

.userinfo-container > span:nth-child(1) {
    color: white;
    font-weight: 600;
    font-size: 15px;
}

.userinfo-container > span:nth-child(2) {
    color: rgb(218, 218, 218);
    font-size: 11px;
}