.Ads {
    margin: 3rem;
    height: 80vh;
    overflow-y: auto;
}

.Ads h3 {
    font-size: 1.2rem;
}

.Ads .ad-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.Ads .ad-grid button {
    border: none;
    border-radius: 10px;
    width: 250px;
    font-size: 1.2rem;
    margin: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #fefefa;
}

.Ads .ad-grid .btn-grey {
    background: #454545;
}
.Ads .ad-grid .btn-red {
    background: #ed2939;
}

.Ads .banner-ad-image {
    width: 500px;
    height: 200px;
}
.Ads .feature-ad-image {
    width: 400px;
    height: 700px;
}
.Ads .resources-ad-image {
    width: 150px;
    height: 500px;
}
.home-ad-image{
    width: 375px;
    height: 730px;
}
.input-grey::-webkit-file-upload-button {
  visibility: hidden;
}
.input-grey::before {
  content: 'Choose New Image';
  display: inline-block;
  padding: 5px 8px;
  background: #454545;
  color: #fefefa;
  border: none;
    border-radius: 10px;
    width: 250px;
    font-size: 1.2rem;
    margin: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
}