.ls-container {
    padding: 20px;
}

.fa-eye:hover {
    cursor: pointer;
}

.ls-button {
    background-color: #353535 !important;
    color: white !important;
    font-size: 11px !important;
    font-weight: 600;
    height: 36px;
    border: none;
    width: 160px;
    border-radius: 5px;
    margin-bottom: -14px;
}

.ls-button:hover {
    cursor: pointer;
    background-color: #000000 !important;
    transition: all 0.3s ease-in-out;
}