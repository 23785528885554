.Packages {
    margin: 1rem;
}
.Packages .plan-validity {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 120px;
    align-items: center;
    justify-items: center;
    border-top: 2px solid #ed2939;
    border-bottom: 2px solid #ed2939;
    background: #ffe0d9;
}
.Packages h4 {
    font-size: 1.4rem;
    font-weight: 150;
    letter-spacing: 2px;

}
.Packages h4 span {
    font-weight: 500;
}
.package-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-items: center;
    margin-top: 50px;
}

.package-cards .package-card {
    display: grid;
    align-items: center;
    justify-items: center;
    padding: 20px 10px;
    width: 300px;
    height: 400px;
}

.package-cards .package-card span {
    font-size: 2.4rem;
    font-weight: 700;
}

.package-cards .package-card .level {
    font-size: 1.4rem;
    font-weight: 700;
}

.package-cards .package-card button{
    border: none;
    color: #fefefa;
    padding: 10px;
    width: 180px;
    border-radius: 10px;
    margin-top: 30px;
    height: 50px;
    font-size: 20px;
}

.package-cards .card-green {
    background: #c5e3c1;
}
.package-cards .card-rose {
    background: #fea5b4;
}
.package-cards .card-blue {
    background: #84d6fe;
}

.package-cards .card-green button:hover {
    background-color: #1c6810;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}
.package-cards .card-rose button:hover {
    background-color: #910a1c;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}
.package-cards .card-blue button:hover {
    background-color: #033250;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.package-cards .card-green .level{
    color: #4d8a44;
}
.package-cards .card-rose .level{
    color: #9e2939;
}
.package-cards .card-blue .level{
    color: #154462;
}

.package-cards .card-green ul li {
    color: #6a8f64;
}
.package-cards .card-rose ul li {
    color: #bc5161;
}
.package-cards .card-blue ul li {
    color: #287193;
}

.package-cards .card-green button{
    background: #4d8a44;
}
.package-cards .card-rose button{
    background: #9e2939;
}
.package-cards .card-blue button{
    background: #154462;
}

.buy_btn:disabled{
cursor: not-allowed;
opacity: 0.4;
}