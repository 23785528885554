.ProductList {
    padding: 0;
    margin: 20px 1rem;
    align-items: center;
}

.ProductList .add-product-button{
    width: 160px;
    border: none;
    background: #ed2939;
    height: 40px;
    position: relative;
    left: 50rem;
    color: #fefefa;
    margin-bottom: 10px;
    border-radius: 25px;
    font-size: 18px;
}

.popup-content {
    background: #454545;
    color: #fefafa;
    min-width: 400px;
}

.table {
    width: 100% ;
    border-collapse: collapse;
    border: 2px solid #9da6a0;
}

.first {
    width: 10vw;
}

.table-head {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.35px;
    padding: 12px;
    vertical-align: top;
    background: #454545;
    color: #fefefa;
}

tr {
    border-bottom: 2px solid #9da6a0;
    min-height: 30px;
}

.table-body {
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.35px;
    padding: 14px;
    text-align: center;
    vertical-align: top;

}

.ProductList i {
    font-size: 24px !important; 
    position: top;
    padding-top: 0;
}

.ProductList .table button {
    background: #454545;
    color: #fefefa;
    border: none;
    padding: 5px;
}

.grey {
    color: rgb(201, 193, 193);
}

.red {
    color: #ed2939;
}

.alert {
    width: 300px;
    height: 200px;
    background: #454545;
    color: #fefefa;
}


@media (max-width: 768px) {
    .table-head {
        display: none;
    }
    .table, .table tbody, .table tr, .table-body {
        display: block;
        width: 100%;
    }
    .tr {
        margin-bottom: 15px;
    }
    .table-body {
        text-align: right;
        padding-left: 50%;
        position: relative;
    }
    .table-body::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        text-align: left;
    }
}