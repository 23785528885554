.Login {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 70vh;
}

.Login .logo {
    background: #454545;
    height: 100vh;
    align-items: center;
    justify-items: center;
    display: flex;
    flex-direction: column;
}

.Login .logo .login-logo{
    position: relative;
    top: 15%;
    /* left: 23%; */
}

.Login .logo h2 {
    position: relative;
    top: 15%;
    /* left: 30%; */
    margin-top: 20px;
    color: #ed2939;
}


.login-form {
    justify-self: center;
    position: relative;
    top: 30px;
    width: 65%;
}

.login-form form {
    margin-top: 14vh;
}

.login-form form p{
    color: red;
}

.login-form div {
    margin-top: 10px;
}

.login-input {
    width: 93%;
    height: 40px;
    border: none;
    box-shadow: 0 0 0 0.5px #b8b8b8;
    font-size: 13px;
    padding: 0px 8px;
    height: 36px;
}

form h5 {
    float: right;
}

form button {
    width: 80%;
    background-color: #ed2939;
    font-size: 16px;
    color: #fefefa;
    text-transform: uppercase;
    border: none;
    border-radius: 25px;
    padding: 10px 0;;
}

.login-form div i {
    color: #b8b8b8;
    position: relative;
    right: 1.6rem;
    top: 2px;
}

.login-form div i:hover {
    cursor: pointer;
}

.login-button {
    width: 200px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    font-size: 15px;
    border-radius: 5px;
}

.login-button > div {
    margin-top: 0px !important;
}

.login-button:hover {
    cursor: pointer;
    background-color: #ff293a;
    box-shadow: 0px 1px 2px 0px grey;
    transition: all 0.3s ease-in-out;
}

.login-button:disabled {
    background-color: rgb(189, 84, 84);
}

.forget-pass {
    padding-right: 2.5vw;
    font-size: 13px;
    margin-top: 20px;
}

.forget-pass:hover {
    cursor: pointer;
    color: grey;
    text-decoration: underline;
    transition: all 0.2s ease-in-out;
}

.invalid-cred {
    font-size: 10px;
    color: red;
    position: absolute;
}