.QuoteEdit {
    margin: 3rem;
}
.CreateQuotes form textarea {
    box-shadow: 0px 1px 2px 0px grey;
    border: none;
    font-size: 14px;
    padding: 10px 20px;
    min-height: 80px;
    max-height: 200px;
    max-width: 600px;
    border-radius: 5px !important;
}
.CreateQuotes button {
    border: none;
    background: #353535;
    color: #fefefa;
    height: 40px;
    width: 136px;
    border-radius: 5px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}
.CreateQuotes button:hover {
    cursor: pointer;
    background-color: #1a1a1a !important;
    transition: all 0.3s ease-in-out;
}