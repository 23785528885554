.CoachProfile {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-items: center;
  margin: 3rem 0;
}
.CoachProfile div {
  width: 88%;
  height: 300px;
  color: white;
}
.CoachProfile div:hover {
  cursor: pointer;
}
.CoachProfile .card {
  display: grid;
  align-items: center;
  justify-items: center;
}
.CoachProfile .card:hover {
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  transition: all 0.3s ease-in-out;
}
.CoachProfile .card h4{
  text-transform: uppercase;
}
.CoachProfile .card-one {
  background: linear-gradient(to bottom, rgba(62, 243, 101), rgb(243, 89, 205));
}
.CoachProfile .card-two {
  background: linear-gradient(to bottom, rgb(252, 91, 51), rgb(255, 236, 236));
}
.CoachProfile .card-three {
  background: linear-gradient(to bottom, rgb(10, 147, 226), rgb(74, 230, 241));
}
.CoachProfile .edit {
  position: relative;
  left: 60%;
  bottom: 30px;
  background: #454545;
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
.CoachProfile .edit .profile-edit {
  color: #ed2939;
  font-size: 1.6rem;
  position: absolute;
  top: 12px;
  left: 15px;
}

.profile-title {
  display: flex;
  align-items: end;
  height: 100%;
  font-weight: bold;
  font-size: 15px;
}

.profile-value {
  margin-top: 10px;
  display: flex;
  align-items: start;
  height: 100%;
  font-size: 5vw;
  font-weight: bolder;
  color: transparent;
  background-color: #565656;
  text-shadow: 0px 2px 3px rgb(255 255 255 / 100%);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
}