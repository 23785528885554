.active {
  color: #ed2939;
}

.side-navbar-coach {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0px;
}

.navbar-logo {
  width: 42%;
}

.userinfo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.userinfo-container > span:nth-child(1) {
  color: white;
  font-weight: 600;
  font-size: 15px;
}

.userinfo-container > span:nth-child(2) {
  color: rgb(218, 218, 218);
  font-size: 11px;
}