.OrderDdetails {
    /* margin: 2rem; */
}
.OrderDdetails form {
    margin-bottom: 30px;
}
.OrderDdetails .form-width-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.OrderDdetails .form-width-2 input{
    width: 240px;
    height: 30px;
    background: #fdfafa;
    border: 1px solid rgb(248, 171, 171);
}

.OrderDdetails .form-width-2 textarea{
    width: 240px;
    min-height: 80px;
    background: #fdfafa;
    border: 1px solid rgb(248, 171, 171);
}

.od-container {
    height: 100%;
    width: 100%;
    padding: 20px;
}

.od-pd-table {
    border: none !important;
    width: calc(100vw - 250px - 40px);
    max-height: 38vh;
    overflow-y: auto;
}