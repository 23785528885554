.Header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 70px;
  border-bottom: 1px solid red;
  padding-left: 10px;
  position: relative;
}
.white-logo {
  grid-column: 1/1;
  grid-row: 1;
}
.Header hr {
  grid-column: 1/3;
  grid-row: 2;
  height: fit-content;
  position: relative;
  top: 10px;
}
.header-logo{
    height: 45px;
}
.icon-log {
  position: absolute;
  top: 18px;
  right: 25px;
}

.i-logout {
  color: #ed2939;
  font-size: 36px;
}

hr {
  color: red;
  width: 100%;
  height: 10px;
}
