.create-blog-container {
    width: 100%;
    height: 100%;
}

.form-container {
    padding: 20px;
    padding-top: 30px;
    width: 90%;
}

.create-blog-title {
    font-weight: 600;
    height: 50px;
    display: flex;
    align-items: center;
}

.label-container {
    height: 28px;
    display: flex;
    align-items: center;
    font-size: 11px;
    margin-left: 1px;
    color: #c3c3c3;
    font-weight: bold;
}

.theme-red {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    height: 40px;
    padding: 1px 11px;
    margin-top: 5px;
    background: #fefaf9;
    border: 2px solid #fbf3f4;
    border-radius: 5px;
    margin-bottom: 22px;
}

.desc-textarea {
    min-height: 110px;
    max-height: 110px;
}

.image-preview-container {
    height: 110px;
    width: 110px;
    background: #fefaf9;
    border: 2px solid #fbf3f4;
    border-radius: 5px;
    position: absolute;
    top: 41px;
    left: 14px;
}

.image-preview-container:hover {
    cursor: pointer;
    background: #fff5f3;
    transition: all 0.3s ease-in-out;
}

.blog-image-preview {
    height: 106px;
    width: 106px;
    object-fit: cover;
    border-radius: 5px;
}

.blog-button {
    width: 200px;
    height: 42px;
    font-weight: 600;
    align-items: center;
    color: white;
    background-image: linear-gradient(#dd715e, #ff6937);
    border-radius: 10px;
    border: none;
}

.blog-button:hover {
    cursor: pointer;
    background-image: linear-gradient(#ff6d53, #ff4d11);
    transition: all 0.3s ease-in-out;
}

.blog-button:disabled {
    background-image: linear-gradient(#d69285, #da8265);
}

.spinner-border {
    width: 1rem !important;
    height: 1rem !important;
}