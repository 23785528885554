.ChangePassword {
    margin: 3rem;
}

.ChangePassword h3 {
    font-size: 1.8rem;
    letter-spacing: 4px;
}

.ChangePassword form {
    display: grid;
    grid-template-rows: 1fr;
    grid-gap: 15px;
    width: 40%;
}
.ChangePassword form input{
    border: 1px solid #e2dede;
    height: 30px;
    border-radius: 25px;
}

.ChangePassword form input:placeholder-shown {
    padding-left: 18px;
}

.ChangePassword form button {
    border: none;
    background: linear-gradient(to bottom, #ff0606, #fc7c04);
}

.ChangePassword form .green {
    color: green;
}

.ChangePassword form .red {
    color: #ed2939;
}