.fi-container {
    padding: 20px;
    overflow-y: auto;
    height: 95vh;
}

.row {
    margin: 0px;
}

.fi-select {
    width: 100%;
    background-color: #fdf7f5;
    border: 1px solid #fbd9de !important;
    border-radius: 2px;
    height: 34px;
    font-size: 12px;
}

.fi-select:hover {
    cursor: pointer;
    background: #fff1ec;
    transition: all 0.3s ease-in-out;
}

.fi-button {
    color: white;
    background: #545454;
    border: none;
    width: 130px;
    height: 28px;
    font-size: 12px;
    border-radius: 2px;
}

.fi-button:hover {
    cursor: pointer;
    background: #3f3f3f;
    transition: all 0.3s ease-in-out;
}

.section-divider {
    background: grey;
    width: 100vw;
    margin-left: -20px;
}