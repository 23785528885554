.CreateSuppliers {
  position: relative;
  left: 16px;
  top: 16px;
}
.CreateSuppliers .form-width-2 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-top: 10px;
}

.CreateSuppliers .form-width input,
select {
  width: 80%;
  height: 40px;
  margin-top: 5px;
  border: none;
  border-radius: 10px;
  background: #f0ebe9;
}

.supplier-button {
  width: 30%;
  margin-top: 20px;
  margin-left: 380px;
  align-items: center;
}

label::after {
  content: "*";
  color: red;
  font-size: 20px;
  position: relative;
  top: 4px;
}
