._formCover_ {
  display: flex;
  align-items: center;
  justify-content: center;
}
._inputEmail_{
  height: 40px;
}
._forgetPassword_ {
  width: 60%;
  grid-column: 2/2;
}
._forgetForm_ {
  display: flex;
  flex-direction: column;
  height: 345px;
  justify-content: space-evenly;
}

@keyframes warningAnimate {
  0% {
    opacity: 0;
    margin-top: 25px;
    margin-left: -20px;
  }
  100% {
    opacity: 1;
    margin-left: -20px;
    margin-top: -150px;
  }
}

.email_warning{
  color: #ff5050;
    font-size: 18px;
    
}

.forget-form {
  justify-self: center;
  position: relative;
  top: 30px;
  width: 65%;
}

