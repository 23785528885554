.DashBoard {
  margin: 0 1rem 1rem 1rem;
}
.DashBoard .cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 4rem 1rem;
  margin-top: 3rem;
}

.DashBoard .cards .card {
  height: 9rem;
  width: 18rem;
  color: #fefefa;
  align-self: center;
  justify-self: center;
  border-radius: 10px;
  /* position: relative; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.DashBoard .dashboard-table h3 {
  color: #ed2939;
}

.DashBoard .cards .card .value {
  font-size: 2.4rem;
  font-weight: 600;
  background-color: #565656;
  color: transparent;
  text-shadow: 0px 2px 3px rgb(255 255 255 / 30%);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
}

.DashBoard .cards .card .name {
  position: absolute;
  top: 7.5rem;
  left: 11px;
  font-size: 0.8rem;
  color: #565656;
}

.DashBoard .cards .card {
  background-color: white;
  border: none;
  box-shadow: 0 19px 38px rgb(0 0 0 / 10%), 0 15px 12px rgb(0 0 0 / 2%);
  position: relative;
  animation: animateShadow 1s ease-in-out;
}

.DashBoard .cards .card:hover {
  cursor: pointer;
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  transition: all 0.3s ease-in-out;
}

@keyframes animateShadow {
  0% {
    box-shadow: 0 1px 4px rgb(0 0 0 / 10%), 0 1px 2px rgb(0 0 0 / 2%);
  }
  100% {
    box-shadow: 0 19px 38px rgb(0 0 0 / 10%), 0 15px 12px rgb(0 0 0 / 2%);
  }
}