.Products {
  position: relative;
  left: 0px;
  top: 0px;
  padding: 30px;
  height :100%;
}
.Products .form-width-2 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-top: 10px;
}
._formField5_ {
  display: flex;
  width: 100%;
  flex-direction: row;
}
._formField3_ {
  display: flex;
  justify-content: space-between;
}

._form3Right_ {
  width: 50%;
}
._innerForm3_ {
  width: 40%;
  justify-content: space-between;
  display: flex;
}
._formImg_ {
  max-width: 90px;
  display: none;
}
.Products .form-width-3 {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  padding-top: 10px;
}

.Products .form-width-5 {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  padding-top: 10px;
}

.Products .form-width input,
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 80%;
  height: 40px;
  padding: 1px 11px;
  margin-top: 5px;
  background: #fefaf9;
  border: 2px solid #fbf3f4;
  border-radius: 5px;
  margin-bottom: 22px;
}
.force-margin {
  margin-bottom: 0px !important;
}
._mrp_{
    width: 100%;
    height: 40px;
    margin-top: 5px;
    background: #f0ebe9;
    border: none;
    border-radius: 5px;
  }
  .formWidth{
      width: 45%;
  }
  ._sale_{
    width: 100%;
    height: 40px;
    margin-top: 5px;
    background: #f0ebe9;
    border: none;
    border-radius: 5px;
  }
._innerForm5_ {
  width: 50%;
  /* margin-top: 20px; */
}
._inner2Form5_ {
  display: flex;
  width: 40%;
}
.product-button {
  width: 30%;
  margin-top: 13px;
  font-weight: 600;
  margin-left: 380px;
  align-items: center;
  background-image: linear-gradient(#dd715e, #ff6937);
  border-radius: 10px;
}

.product-button:hover {
  cursor: pointer;
  background-image: linear-gradient(#ff6d53, #ff4d11);
  transition: all 0.3s ease-in-out;
}

.add-product-title {
  font-size: 20px;
  margin: 0;
  font-weight: 600;
}

.label-container-product {
  height: 28px;
  display: flex;
  align-items: center;
  font-size: 11px;
  margin-left: 1px;
  color: #c3c3c3;
  font-weight: bold;
  margin-bottom: 0;
}

.cornerco-form-theme {
  background: #fefaf9;
  border: 2px solid #fbf3f4;
  padding: 10px 11px;
}

.image-preview {
  background: #fefaf9;
  border: 2px solid #fbf3f4;
  height: 60%;
  width: 90%;
  position: absolute;
  border-radius: 5px;
}

.image-preview:hover {
  cursor: pointer;
  background: #fff5f3;
  transition: all 0.3s ease-in-out;
}

._formWidth_ {
  width: 33.33%;
  position: relative;
}

.image-previewer {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.promocode::after {
  content: '';
}

.product-button:disabled {
  background-image: linear-gradient(#fcad9f, #b36e58);
}

.promocode-star {
  margin-left: 1px;
  margin-top: 8px;
  font-size: 21px;
  color: red;
}

._sale_:disabled {
  background: #ebebeb;
  border: 2px solid #ebebeb;
}